import anime from 'animejs';
import Typed from 'typed.js';
import { Component } from 'nospa';

import mediaBreakpoints from '@/helpers/mediaBreakpoints';

export default class MainSection extends Component {
  onInit() {
    this.titleRef = this.$getRef('title');
    this.titleTypedRef = this.$getRef('titleTyped');
    this.boxRef = this.$getRef('box');
    this.headerRef = this.$getRef('header');

    this.titleTypedRef.el.style.minHeight = `${this.titleRef.el.offsetHeight}px`;
    this.titleRef.el.style.display = 'none';
    this.startTypingTitle = this.startTypingTitle.bind(this);

    this.startAnimation();
  }

  startAnimation() {
    if (mediaBreakpoints.isDown(mediaBreakpoints.breakpoints.MD)) {
      this.startTypingTitle();
      return;
    }

    const timeline = anime.timeline();
    timeline
      .add({
        targets: this.boxRef.el,
        translateY: ['-20%', '10%'],
        delay: 250,
        duration: 1000,
        easing: 'linear',
      })
      .add({
        targets: this.boxRef.el,
        translateY: ['10%', 0],
        duration: 500,
        easing: 'easeOutQuad',
        complete: this.startTypingTitle,
      })
      .add({
        targets: this.headerRef.el,
        opacity: [0, 1],
        translateY: [25, 0],
        delay: 1000,
        duration: 750,
        easing: 'easeOutQuad',
      });
  }

  startTypingTitle() {
    this.typed = new Typed(this.titleTypedRef.el, {
      stringsElement: this.titleRef.el,
      typeSpeed: 20,
      showCursor: false,
    });
  }

  onDestroy() {
    this.typed = null;
  }
}

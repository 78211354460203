import 'browser-environment';
import { App } from 'nospa';

import '@/styles/styles.scss';

import AnimationAppear from '@/components/AnimationAppear';
import MainSection from '@/components/MainSection';

window.App = new App({
  el: 'body',
  components: {
    AnimationAppear,
    MainSection,
  },
  directives: {},
  methods: {},
  data: window.APP_CONFIG || {},
});

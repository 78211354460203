import anime from 'animejs';
import { Component } from 'nospa';
import env from 'browser-environment';

export default class AnimationAppear extends Component {
  onInit() {
    this.$el.style.opacity = '0';

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [],
    };

    for (let i = 0; i <= 1; i += 0.05) {
      options.threshold.push(i);
    }

    const startPoint = { value: 20, unit: 'vh' };

    if (this.$props.start) {
      // TODO: validate values before
      startPoint.value = parseInt(this.$props.start, 10);
      startPoint.unit = (this.$props.start.match(/(%|px|vh)$/) || ['vh'])[0];
    }

    this.observer = new window.IntersectionObserver((entries) => {
      const [entry] = entries;

      let isStarted;

      if (entry.intersectionRatio >= 0.9) {
        isStarted = true;
      } else if (startPoint.unit === '%') {
        // TODO: fix if % -> px > viewport height
        isStarted = entry.intersectionRatio >= startPoint.value / 100;
      } else if (startPoint.unit === 'px') {
        // TODO: fix if px > viewport height
        isStarted = entry.target.offsetHeight * entry.intersectionRatio >= startPoint.value;
      } else {
        const vh = ((entry.target.offsetHeight * entry.intersectionRatio) / env.viewport.h) * 100;
        isStarted = vh >= startPoint.value;
      }

      if (isStarted) {
        anime({
          targets: this.$el,
          translateY: ['40px', 0],
          opacity: [0, 1],
          duration: 400,
          easing: 'easeOutQuad',
        });
        this.removeObserver();
      }
    }, options);

    this.observer.observe(this.$el);
  }

  removeObserver() {
    this.observer.disconnect();
    this.observer = null;
  }

  onDestroy() {
    this.removeObserver();
  }
}
